<template>
  <div class="show" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
    <div class="grxx">
      <van-row>
        <van-col span="24">
          <div class="customer">
            <van-field v-model="customer" input-align="right" label="*客户名称："/>
          </div>
          <div>
            <van-field v-model="type" input-align="right" label="*企业类型："/>
          </div>
          <div>
            <van-field v-model="nature" input-align="right" label="*企业性质："/>
          </div>
          <div>
            <van-field v-model="level" input-align="right" label="*授信等级："/>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="jbxx">
      <van-row>
        <van-col span="12">
          <div>
            <van-field v-model="post" label="职务 ："/>
          </div>
        </van-col>
        <van-col span="12">
          <div>
            <van-field v-model="name" label="姓名 :"/>
          </div>
        </van-col>
      </van-row>
      <van-row>
        <div>
          <van-field span="24" v-model="tel" label="电话 :"/>
        </div>
      </van-row>
      <van-row>
        <div>
          <van-field span="24" v-model="customerInfo.addressDetailInfo.address" label="地址：" @click="showMap"/>
          <!--          <van-field span="24" v-model="customerInfo.addressDetailInfo.longitude" label="经度 :" type="number"/>-->
          <!--          <van-field span="24" v-model="customerInfo.addressDetailInfo.latitude" label="纬度 :" type="number"/>-->
        </div>
      </van-row>
    </div>
    <div class="lxrtc">
      <van-cell is-link @click="showPopup()" title="更多地址/联系人" title-class="lxrtc-text"/>
    </div>
    <div class="hlnx">
      <van-row>
        <div class="color-l">
          <van-col span="12">
            <van-cell-group>
              <van-field v-model="hl" label="货量(万吨) ："/>
            </van-cell-group>
          </van-col>
          <van-col span="12">
            <van-field v-model="ys" label="营收(万元) ："/>
          </van-col>
        </div>

        <van-col span="24">
          <div>
            <van-field v-model="begintime" input-align="right" label="开始年协时间 ："/>
          </div>
        </van-col>
        <van-col span="24">
          <div>
            <van-field v-model="endtime" input-align="right" label="结束年协时间 :"/>
          </div>
        </van-col>
      </van-row>
      <van-row>
        <div>
          <van-field span="24" v-model="yearperson" input-align="right" label="*协议负责人 :"/>
        </div>
      </van-row>
    </div>
    <div class="ly">
      <van-field
          v-model="message"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          maxlength="50"
          placeholder="请输入备注"
          show-word-limit
      />
    </div>
    <div class="yjgsbj">
      <van-row>
        <van-col  span="16">
          <van-field v-model="yjgs" input-align="right" label="一级公司"/>
        </van-col>
        <van-col is-link @click="yjgstz()" span="8" :style="{color:'#6390E3', fontSize:'13px',marginTop:'12px'}">
          详细信息
        </van-col>
      </van-row>
    </div>

    <!-- 百度地图位置选择 -->
    <BMapAddressSelect :addressDetailInfo="this.customerInfo.addressDetailInfo" ref="bmapAddressSelect"
                       @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>

    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>

</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import BMapAddressSelect from "@/components/BMapAddressSelect/index";

export default {
  name: "CustomerShowHF",
  components: {CrmTabBar, BMapAddressSelect},
  data() {
    return {
      customer: '中国诚通国际贸易有限公司',
      type: '钢铁',
      nature: '贸易型',
      level: 'A',
      post: '经理',
      name: '胡锦',
      tel: '12345678911',
      message: '盖阔',
      hl: '37.6万吨',
      ys: '17.63万元',
      hl1: '37.6万吨',
      ys1: '17.63万元',
      begintime: '2019-05-04',
      endtime: '2020-07-08',
      yearperson: '马兆磊',
      begintime1: '2019-05-04',
      endtime1: '2020-07-08',
      yearperson1: '马兆磊',
      yjgs: '鞍钢股份集团有限公司',
      //列表高度
      freshHeight: 0,
      customerInfo: {
        addressDetailInfo: {
          id: '',
          addressId: '',
          contact: '',
          post: '',
          tel: '',
          address: '',
          longitude: '',
          latitude: '',
          province: '',
          city: '',
          district: ''
        },
      },
    }
  },
  methods: {
    /** 显示地图 */
    showMap() {
      this.$refs.bmapAddressSelect.show();
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.customerInfo.addressDetailInfo = addressDetailInfo;
    },
    showPopup() {
      this.$router.push("BulkCrmsCusAddressDetailList/123")
    },
    yjgstz() {
      this.$router.push("CustomerListEdit/123")
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
  },

}

</script>

<style scoped>

.grxx {
  margin: 10px 5px;
  padding: 5px;
  border: 1px solid #C2C2C2;
  border-radius: 5px;
  background: white;
  box-shadow: darkgrey 2px 5px 20px 5px;
}

.jbxx {
  margin: 10px 0px;
  padding: 5px;
  border: 1px solid #5CA4F4;
  border-radius: 5px;
  background: white;
}

.lxrtc, .ttxz, .ejgs ,.yjgsbj{
  margin: 5px 5px;
  padding: 3px;
  border-radius: 5px;
  background: white;
  height: 40px;
}

.ly {
  margin: 5px 5px;
  padding: 3px;
  border-radius: 5px;
  background: white;
}

.lxrtc{
  color: #5CA4F4;
  text-align: left;
}

</style>
<!--样式穿透 不能在<style scoped>中修改已给定样式，可以使用样式穿透-->
<style>
.grxx .van-field__control--right {
  color: #5CA4F4;
  font-size: 13px;
}

.grxx .van-field, .hlnx .van-field,.jbxx .van-field, .yjhlnx .van-field{
  padding-top: 3px;
  height: 30px;
  font-size: 12px;
}
.ly .van-field,.ejgs .van-field{
  padding-top: 3px;
  font-size: 12px;
}
.lxrtc .van-cell {
  color: #5CA4F4;
  font-size: 12px;
}
.ttxz .van-cell {
  color: #5CA4F4;
  font-size: 12px;
}
.van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}
.hlnx {
  margin: 10px 0px;
  padding: 5px;
  border: 1px solid #5CA4F4;
  border-radius: 5px;
  background: white;
}
.yjhlnx {
  margin: 10px 0px;
  padding: 5px;
  border: 1px solid #5CA4F4;
  border-radius: 5px;
  background: white;
}

.yjgsbj {
  margin: 10px 0px;
  padding: 5px;
  border-radius: 5px;
  background: white;
}
.yjgsbj .van-field{
  font-size: 12px;
}
.color-l .van-cell__title,.color-l  .van-field__control{
  color: #5CA4F4;
}

</style>
